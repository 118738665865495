import React from 'react'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaStar } from "react-icons/fa6";

import one from "../../assets/testimonials/ka-khaled-pic.webp"
import two from "../../assets/testimonials/2.webp"
import three from "../../assets/testimonials/ka-zeeshan-pic.webp"
import four from "../../assets/testimonials/4.webp"
import five from "../../assets/testimonials/5.webp"

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const Testimonials = () => {
  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='bg-gray-50 py-[70px] w-full flex items-center justify-center max-575:py-[50px]'>
      <div className='container flex flex-col items-center justify-center gap-y-11'>

        <div className="heading-wrap items-center">
          <span className='section-sub-text'>Testimonials</span>
          <h2 className='Section-heading'>
            Hear What our clients Have to Say About Us
          </h2>
          <p className='section-discrption'>The feedback that our clients give us at Sourcebee says a lot about how committed we are to do our best. Here are some reviews from people we value!</p>
        </div>

        <ScrollAnimation animateIn="fadeIn" className="reviews-wrap w-full">
          <Slider {...settings} className='max-1024:w-[90%] max-1024:m-auto'>
            <div className="review-single">
              <p className='testimonial-content'>
                Sourcebee's approach changed our online presence for the better. The knowledge and hard work of their team helped us get results that could be measured.
              </p>
              <div className='star-icons'>
                <FaStar />  <FaStar /> <FaStar /> <FaStar />  <FaStar />
              </div>
              <div className='testimonial-author-wrap'>
                <img src={one} alt="" />
                <div className='testimonial-author-name-wrap'>
                  <span className='testimonial-author-name'>Khaled Ali</span>
                  <span className='testimonial-author-role'>Founder, Legend1st</span>
                </div>
              </div>

            </div>
            <div className="review-single">
              <p className='testimonial-content'>
                Working with Sourcebee have changed everything for us. Their strategic ideas and customized solutions made our brand much more visible and engaged.
              </p>
              <div className='star-icons'>
                <FaStar />  <FaStar /> <FaStar /> <FaStar />  <FaStar />
              </div>
              <div className='testimonial-author-wrap'>
                <img src={two} alt="" />
                <div className='testimonial-author-name-wrap'>
                  <span className='testimonial-author-name'>Vinay Kumar Sen</span>
                  <span className='testimonial-author-role'>Founder, Desert Oasis Tours</span>
                </div>
              </div>

            </div>
            <div className="review-single">
              <p className='testimonial-content'>
                Sourcebee is very professional and creative, so I highly suggest them. They made a fast and responsive website that fits our brand personality exactly.
              </p>
              <div className='star-icons'>
                <FaStar />  <FaStar /> <FaStar /> <FaStar />  <FaStar />
              </div>
              <div className='testimonial-author-wrap'>
                <img src={three} alt="" />
                <div className='testimonial-author-name-wrap'>
                <span className='testimonial-author-name'>Zeeshan Siyal</span>
                <span className='testimonial-author-role'>Founder, Zeeshan Travel and Tourism</span>
                </div>
              </div>

            </div>
            {/* <div className="review-single">
              <p className='testimonial-content'>
                Lorem ipsum dolor sit amet consectetur adipis icing elit. Lorem ips um dolor sit amet cons ectetur adipisicing elit. Ad earum tenetur accusantium ipsa expedita
              </p>
              <div className='star-icons'>
                <FaStar />  <FaStar /> <FaStar /> <FaStar />  <FaStar />
              </div>
              <div className='testimonial-author-wrap'>
                <img src={four} alt="" />
                <div className='testimonial-author-name-wrap'>
                  <span className='testimonial-author-name'>Russell J. Cash </span>
                  <span className='testimonial-author-role'>Founder, 189 Media</span>
                </div>
              </div>

            </div> */}
            {/* <div className="review-single">
              <p className='testimonial-content'>
                Lorem ipsum dolor sit amet consectetur adipis icing elit. Lorem ips um dolor sit amet cons ectetur adipisicing elit. Ad earum tenetur accusantium ipsa expedita
              </p>
              <div className='star-icons'>
                <FaStar />  <FaStar /> <FaStar /> <FaStar />  <FaStar />
              </div>
              <div className='testimonial-author-wrap'>
                <img src={five} alt="" />
                <div className='testimonial-author-name-wrap'>
                  <span className='testimonial-author-name'>Russell J. Cash </span>
                  <span className='testimonial-author-role'>Founder, 189 Media</span>
                </div>
              </div>

            </div> */}
          </Slider>
        </ScrollAnimation>
      </div>

    </section>
  )
}

export default Testimonials
